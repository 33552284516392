import React from "react";
import { graphql } from "gatsby";
import Container from "../../components/container";
import Header from "../../components/header";
import MoreStories from "../../components/more-stories";
import PostBody from "../../components/post-body";
import PostHeader from "../../components/post-header";
import SectionSeparator from "../../components/section-separator";
import { HelmetDatoCms } from "gatsby-source-datocms";
import ProjectCategoryHeader from "../../components/project-category-header";
import '../../styles/project-category.scss';
import Project from "../../components/project";
import Footer from "../../components/footer";

export default function ProjectCategory({ data: { site, projectCategory } }) {
  const { project: projects } = projectCategory
  console.log(projectCategory)
  return (

    <Container>
      <Header />
      <main>
        <section className="project-category">
          <ProjectCategoryHeader data={projectCategory} />

        </section>
        <section className="project-wrapper">
          {projects.map((p, i) => <Project key={i} data={p} />)}
        </section>
      </main>
      <HelmetDatoCms seo={projectCategory.seo} favicon={site.favicon} />

      {/* //   <Header />
    //   <article>
    //     <ProjectCategory

    //     />
    //     <PostBody content={post.content} />
    //   </article>
    //   <SectionSeparator />
    //   {morePosts.nodes.length > 0 && <MoreStories posts={morePosts.nodes} />} */}
      <Footer />
    </Container>
  );
}

export const query = graphql`
  query ProjectCategory($id: String) {
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    projectCategory: datoCmsProjectCategory(id: { eq: $id }) {
      categoryDescription
      categoryHeading
      categoryTitle
      slug
      categoryHeroImage {
            gatsbyImageData(width: 1500)
      }
      project {
        id
        title
        projectDuration
        valueOfWork
        year
        subtitle
        descriptionHeading
        projectImages {
            gatsbyImageData(width: 1500)
        }
        descriptionText 
    }
  }
        
  }
`;
