import React from "react";
import Project from "./project";
import { GatsbyImage } from "gatsby-plugin-image";
import '../styles/category-header.scss';

const ProjectCategoryHeader = ({ data }) => {
  const { title, categoryHeroImage, categoryDescription, categoryTitle, categoryHeading } = data;
  console.log(categoryHeroImage)
  return (
    <div className="category-header">
      <div className="category-info">
        <div className="category-content">
          <h1 className="fs-primary-heading-alternative">{categoryTitle}</h1>
          <p className="description fs-large-body">{categoryHeading}</p>
          <p className="title-description">{categoryDescription}</p>
        </div>
      </div>
      <div className="category-image">
        <GatsbyImage
          key={title}
          image={categoryHeroImage.gatsbyImageData}
          placeholder="blurred"
          className="logo"
          //style={{ marginRight: isMobile ? '0' : '2rem' }}
          formats={["auto", "webp", "avif"]}
          alt={`${title}`}
          transformOptions={{ fit: "cover", cropFocus: "attention" }}
        />

      </div>

    </div>
  );
};

export default ProjectCategoryHeader;